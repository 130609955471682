import { graphql, PageProps } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { ArticleHero } from '../components/article-hero/article-hero';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';
import { Footer } from '../components/footer/footer';
import { Navbar } from '../components/navbar/navbar';
import RichText from '../components/rich-text/rich-text';
import { SeoComponent } from '../components/seo-component/seo-component';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { CASE_STUDIES_URL } from '../const/url';
import { withCookieBar } from '../hoc/withCookieBar';
import { CaseStudyArticleModel } from '../models/case-study-article-model';
import { ICategoryModel } from '../models/category-model';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.scss';
import '../styles/reset.css';
import { pageDataResolver } from '../utils/pageDataResolver';

export interface ICaseStudyArticleData {
  language: string;
  id: string;
  slug: string;
  title: string;
  subTitle: string;
  summary: string;
  publicationDate: string;
  image: {
    url: string;
    description: string;
  };
  author: IEmployeeBoxProps;
  content: {
    value: IKontentResolvedData;
    modular_content: IKontentItem[];
  };
  tags: ICategoryModel[];
  country: string;
}

interface IData {
  kontentItemCaseStudyArticle: ICaseStudyArticle;
  allKontentItemCaseStudyArticle: {
    nodes: ICaseStudyNext[];
  };
  kontentItemPage: IPage;
  cookieSection: ICookieData;
}

type CaseStudyArticlePageProps = PageProps<IData, IPageContext>;

const CaseStudyArticlePage: FunctionComponent<CaseStudyArticlePageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    handleResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    state.showActiveDevice,
    state.showPageState.currentUri,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    props.location.state,
    props.data.cookieSection.elements.text.value,
    props.data.cookieSection.elements.button_cta.value
  ]);

  const caseStudyData: ICaseStudyArticleData = useMemo(
    () => CaseStudyArticleModel.create(props.data.kontentItemCaseStudyArticle),
    [props.data.kontentItemCaseStudyArticle]
  );

  const nextCaseStudyData: ICaseStudyNext = useMemo(
    () => props.data.allKontentItemCaseStudyArticle.nodes[0],
    [props.data.allKontentItemCaseStudyArticle.nodes]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <ArticleHero
        linkBackUrl={CASE_STUDIES_URL}
        backgroundImage={caseStudyData.image}
        headline={caseStudyData.title}
        subTitle={caseStudyData.subTitle}
        tags={caseStudyData.tags}
        // bottomInfo={caseStudyData.country} //TODO: disabled temporary, fix after publish
        title="CASE STUDIES"
      />
      {caseStudyData && (
        <RichText
          {...caseStudyData.content}
          nextCaseStudy={nextCaseStudyData}
        />
      )}
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export const Head = (props: CaseStudyArticlePageProps) => {
  const seoData = useMemo(
    () =>
      props.data.kontentItemCaseStudyArticle.elements
        ? seoDataResolver(props.data.kontentItemCaseStudyArticle.elements)
        : null,
    [props.data.kontentItemCaseStudyArticle.elements]
  );

  return <SeoComponent {...seoData} />;
};

export default withAppStore(
  withCookieBar(CaseStudyArticlePage as FunctionComponent<{}>)
);

export const query = graphql`
  query($id: String, $pageLanguage: String) {
    kontentItemCaseStudyArticle(
      system: { id: { eq: $id }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentCaseStudyArticle
    }
    allKontentItemCaseStudyArticle(
      filter: { system: { language: { eq: $pageLanguage }, id: { ne: $id } } }
      limit: 1
    ) {
      nodes {
        ...FragmentCaseStudyNextItem
      }
    }
    kontentItemPage(
      system: { language: { eq: $pageLanguage }, codename: { eq: "homepage" } }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
